<template>
	<div class="page_content">
		<div class="page_detail">
			
			<a-divider orientation="left">模板消息</a-divider>
			<div class="push_box">
				<div class="push_title">润田公司销售出库单-示例</div>
				<div class="push_item">
					<span class="lable_text">客户名称：</span><span>大余县安府商行</span>
				</div>
				<div class="push_item">
					<span class="lable_text">出库单号：</span><span> 0000627163</span>
				</div>
				<div class="push_item">
					<span class="lable_text">产品：</span>
					<div class="good_item">
						<p>润田翠天然含硒矿泉水(蓝瓶)500ml*24瓶：130件；</p>
						<p>润田翠天然含硒矿泉水(蓝瓶)350ml*24瓶：130件；</p>
					</div>
				</div>
				<div class="push_item">
					<span class="lable_text">发货金额：</span><span>20,030.00元</span>
				</div>
				<div class="push_item">
					<span class="">本月发送总额：</span><span></span>
				</div>
				<p class="push_item go_more">
					<span>详情</span><span><a-icon type="right" /></span>
				</p>
			</div>
			<a-descriptions :column="1">
				<a-descriptions-item label="默认发送人员 ">
					相关经销商，区域主管，区域经理
				</a-descriptions-item>
				<a-descriptions-item label="新增发送人员 ">
					<div class="push_item">
						<div>
							<div class="cont_box" v-for="(item,index) in con_lit">
								<div class="cont_item">
									<p>姓名：{{item.username}}</p>
									<p>部门：{{item.department}}</p>
								</div>
								<span class="add_man del_iocn">
									<a-icon type="minus-circle"  @click='del_item(item.id)'/>
								</span>
					
							</div>
							<p class="add_man">
								<a-icon type="plus-circle" @click='add_department' />
							</p>
						</div>
					</div>
				</a-descriptions-item>
				<!-- <a-descriptions-item label="新增发送人员 "> -->
			</a-descriptions>

			<a-divider orientation="left">签名</a-divider>
			<div>
				<div class="sign">
					<span>经销商签名：</span>
					<span>
						<a-switch default-checked v-model="sign_status.distribute" @change="signChange()"/>
					</span>
				</div>
				<div class="sign">
					<span>发送签名模板消息：</span>
					<span>
						<a-switch default-checked v-model="sign_status.send_confirm" @change="signChange()"/>
					</span>
				</div>
			</div>
			
			<a-divider orientation="left">详情展示内容</a-divider>
			<div>
				<p>
					<a-button :type="primary==1?'primary':''" @click='change_pri(1)'>桶装</a-button>
					<a-button :type="primary==2?'primary':''" @click='change_pri(2)' class='right_btn'>瓶装</a-button>
				</p>
				<div class="info_box">
					<div>
						<p class="checked_btn"  v-show="primary==1">
							<a-checkbox v-model:checked="allchecked" @change='changeStatus(1)'>全选</a-checkbox>
						</p>
						<p class="checked_btn" v-show="primary==2">
							<a-checkbox v-model:checked="allchecked2" @change='changeStatus(1)'>全选</a-checkbox>
						</p>
						<div class="tab_box" v-show="primary==1">
							<div class="tab_title">
								<div class="tab_item">
									<div class="title_box">
										<p>项目名称</p>
										<p>是否展示</p>
									</div>
									<div class="item_box">
										<div v-for="item in drum.slice(0,10)">
											<p>{{item.name}}</p>
											<p> <a-checkbox v-model:checked="item.value" @change='changeStatus(2)'></a-checkbox></p>
										</div>
									</div>

								</div>
							</div>
							<div class="tab_title">
								<div class="tab_item">
									<div class="title_box">
										<p>项目名称</p>
										<p>是否展示</p>
									</div>
									<div class="item_box">
										<div v-for="item in drum.slice(10,drum.length)">
											<p>{{item.name}}</p>
											<p> 
											<a-checkbox v-model:checked="item.value" @change='changeStatus(2)'></a-checkbox>
											</p>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="tab_box" v-show="primary==2">
							<div class="tab_title">
								<div class="tab_item">
									<div class="title_box">
										<p>项目名称</p>
										<p>是否展示</p>
									</div>
									<div class="item_box">
										<div v-for="item in bottled.slice(0,10)">
											<p>{{item.name}}</p>
											<p> <a-checkbox v-model:checked="item.value" @change='changeStatus(2)'></a-checkbox></p>
										</div>
									</div>
						
								</div>
							</div>
							<div class="tab_title" v-if="bottled.length>10">
								<div class="tab_item">
									<div class="title_box">
										<p>项目名称</p>
										<p>是否展示</p>
									</div>
									<div class="item_box">
										<div v-for="item in bottled.slice(10,bottled.length)">
											<p>{{item.name}}</p>
											<p> 
											<a-checkbox v-model:checked="item.value" @change='changeStatus(2)'></a-checkbox>
											</p>
										</div>
									</div>
						
								</div>
							</div>
						</div>
					</div>
					<div class="phone">
						<div class="show_box">
							<Show :new_combination='new_combination' :new_info.sync='new_info' :sign_status="sign_status"></Show>
						</div>
					</div>
				</div>

			</div>

			<a-divider orientation="left">产品名称与数量组合</a-divider>
			<div>
                <a @click="add_news()">
                    <a-icon type="plus-circle" />
                    <span style="padding-left: 5px">添加字段</span>
                </a>
                <a-input type="hidden" name="new_combination" v-decorator="['new_combination', new_combination]" />
                <div>
                    <ul>
                        <li v-for="(item, index) in new_combination" :key="index">
                            <div class="com_box">
								<div class="com_info" style="width:120px;">
									<span class="info_color">排序：</span>
									<a-input v-model="item.sort" placeholder="请输入序号" min="0" type='number' style="width:70px" @change="input_change(index,item.sort)"/>
									<!-- <span>{{ item.sort }}</span> -->
								</div>
                                <div class="com_info" style="width:180px">
									<span class="info_color">组合名称：</span>
									<span>{{ item.name }}</span>
								</div>
                                <div class="com_info">
									<span class="info_color">规则：</span>
									<span>{{item.rule}}</span>
								</div>
								<div class="com_info">
									<a @click="del_new(index)">删除</a>
								</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
         
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>
		
		<EditPop ref="edit" @tree_select="tree_select" @handleSubmit="submit"
			:form_data="form_data_seo" :visible.sync="visible">
			<div slot="uid">
				<a @click="add_user()">
					<a-icon v-if="!select_user.username" type="plus-circle" />
					<span style="padding-left: 5px">{{
			            select_user.username
			                ? select_user.username
			                : "添加发送人"
			        }}</span>
				</a>
			</div>
			<template slot="oppenid" v-show="select_user.username">
				<div>
					<div id="qrCodeUrl"  ref="qrCodeUrl">
					
					</div>
					<p>请使用需要绑定的人员使用微信扫一下二维码</p>
				</div>
			</template>
		</EditPop>
		<addRule @handleSubmit="combination_submit" :form_data="form_data_combination" :visible.sync="combination_visible" :width="'700px'" :combina="select_list" :combina_methord="combina_methord" :form_data_all="form_data_all" @get_form_data_all="get_form_data_all" @del_data="del_data" @cancle_rule="cancle_rule"></addRule>
		<!-- 选择用户 -->
		<SelectUser @submit="submit2" :visible.sync="user_visible"></SelectUser>
	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import addRule from "./components/add_rule.vue";
	import LookImages from "@/components/LookImages";
	import Show from "./components/show.vue";
	
	import {
		setting_detail,setting_index,setting_del,setting_select,setting_save_field,setting_save_status
	} from '@/api/push.js'
	import SelectUser from "@/components/SelectUser";
	import QRCode from 'qrcodejs2'
	export default {
		components: {
			LookImages,
			EditPop,
			SelectUser,
			addRule,
			Show
			// RightOutlined
		},
		data() {
			return {
				sign_status: {
					distribute:true
				},
				new_combination:[],
				combination_visible:false,
				code_src:'',
				select_user:{
					
				},
				new_info:{},
				primary:1,
				drum:[],
				drum_goods:[],
				drum_show:[],
				bottled:[],
				bottled_goods:[],
				bottled_show:[],
				user_visible:false,
				allchecked: false,
				allchecked2: false,
				activeKey: 1,
				size: 'default',
				activeKey: 1,
				visible: false,
				form_data_seo: {
					title: "添加发送人员",
					list: [{
							type: "slot",
							name: "uid",
							title: "姓名",
						},
						{
							type: "slot",
							name: "oppenid",
							title: "绑定OPENID",
						},
						{
							type: "text",
							name: "openid",
							title: "绑定OPENID",

						},
					]
				},

				form_data_all:[],	//lili
				form_data_combination: {
					name:"",
					sort:""
				},
				detail: {
					trip: {},
					check_in: []
				},
				con_lit: [],
				select_list:[],
				combina_methord:[
					{
						key:"",
						value:"请选择"
					},
					{
						key:"+",
						value:"加",
					},
					{
						key:"-",
						value:"减",
					},
					{
						key:"*",
						value:"乘",
					},
					{
						key:"/",
						value:"除"
					}
				]
			}
		},
		created() {
			this.get_info()
			this.get_select()
		},
		methods: {
			
			get_form_data_all(){
				let rule = {
					operator:'',
					field:[],
					join_operator:''
				}
				this.form_data_all.push(rule)
			},
			change_pri(type) {
				this.primary = type
				if (this.primary == 1) {
					this.new_combination = this.drum_show
				} else {
					this.new_combination = this.bottled_show
				}
				this.change_info()
			},

			creatQrCode() {
			document.getElementById("qrCodeUrl").innerHTML = "";
			 let qrcode = new QRCode('qrCodeUrl', {
			 	width: 170,
			 	height: 170,
			 	text: this.url+'?uid='+this.select_user.id, // 二维码内容  
			 	correctLevel: QRCode.CorrectLevel.H,
			 	render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）  
			 })
			},
			add_user() {
				this.user_visible = true;
			},
			submit2({
				data
			}) {
				this.user_visible = false;
				this.select_user = data;
				this.creatQrCode()
			},
			del_item(id){
                setting_del({
                    data:{
                       id:id 
                    }
                }).then(res=>{
                    this.get_info()
                })
			},
			changeStatus(type){
				//先将数据转成数字传给接口
				if (this.primary == 1) {
					this.drum.forEach(item=>{
						if(type==1 && this.primary==1){
							if(this.allchecked){
								item.value=1
							}else {
								item.value=0
							}
							
						}else {
							if(item.value){
								item.value=1
							}else{
								item.value=0
							}
						}
						
					})
				} else {
					this.bottled.forEach(item=>{
						if(type==1 && this.primary==2){
							if(this.allchecked2){
								item.value=1
							}else {
								item.value=0
							}
						}else {
							if(item.value){
								item.value=1
							}else{
								item.value=0
							}
						}
						
					})
				}
				
				let obj={}
				if(type=='user'){
					 obj = {
						type:'user',
						openid:this.openid,
						uid:this.select_user.id
					}
				}else {
					 obj = {
						drum:this.drum,
						bottled:this.bottled,
						user:this.con_lit
					}
				}

				
				setting_index({data:obj}).then(res=>{
					this.get_info();
					this.select_user={}
				})
			},
			tree_select(e) {
				this.name = e[0];
			},
			add_department() {
				this.visible = true;
			},
			
			submit(e) {
				this.openid=e.values.openid
				this.uid=this.select_user.id
				this.visible = false;
				this.changeStatus('user')
			},
			
			get_info() {
				setting_detail().then(res => {
					let temp1= 0
					let temp2= 0
					this.url = res.data.url
					this.drum = res.data.data.drum;
					this.con_lit= res.data.user
					this.bottled=res.data.data.bottled
					this.drum.forEach(item=>{
						if(item.value==1){
							item.value=true
						}else{
							temp1++
							item.value=false
						}
					})
					if(temp1>0){
						this.allchecked=false
					}else {
						this.allchecked=true
					}
					this.bottled.forEach(item=>{
						if(item.value==1){
							item.value=true
						}else{
							temp2++
							item.value=false
						}
					})
					
					if(temp2>0){
						this.allchecked2=false
					}else {
						this.allchecked2=true
					}
					this.drum_goods = res.data.rule_data.drum_goods
					this.bottled_goods = res.data.rule_data.bottled_goods
					//页面展示
					this.drum_show = res.data.rule_data.drum_show
					this.bottled_show = res.data.rule_data.bottled_show
					if (this.primary == 1) {
						this.new_combination = this.drum_show
					} else {
						this.new_combination = this.bottled_show
					}
					if (res.data.sign_status) {
						for (let item in res.data.sign_status) {
							this.sign_status[item] = res.data.sign_status[item] ? true : false
						}
					}
					this.change_info();
				})
			},
			add_news(e) {
				this.combination_visible = true
				this.get_form_data_all()
			},
			combination_submit(e) {
				let new_array = e
				if (this.primary == 1) {
					this.drum_goods.push(new_array)
				} else {
					this.bottled_goods.push(new_array)
				}
				this.save_field();
				this.cancle_rule();
			},
			get_select() {
                setting_select().then(res => {
					this.select_array = res.data.data
					this.select_list = res.data.list
                })
            },
			del_data(e) {
				this.form_data_all.splice(e,1)
			},
			cancle_rule() {
				this.combination_visible = false
				this.form_data_all = []
				this.form_data_combination = {}
			},
			save_field() {
				var flag = 'drum_goods';
				var rule = this.drum_goods;
				if (this.primary == 2) {
					flag = 'bottled_goods';
					rule = this.bottled_goods;
				}
				setting_save_field({
					data:{
						flag:flag,
						field_rule:rule
					},
					Toast:true
				}).then(res => {
					this.get_info();
				})
			},
			del_new(index) {
				if (this.primary == 1) {
					this.drum_goods.splice(index,1);
				} else {
					this.bottled_goods.splice(index,1);
				}
				this.save_field()
			},
			input_change(index,e) {
				if (e !== '') {
					if (this.primary == 1) {
						this.drum_goods[index].sort = e
					} else {
						this.bottled_goods[index].sort = e
					}
					this.save_field()
				}
			},
			signChange() {
				var obj = {}
				for (let item in this.sign_status) {
					obj[item] = this.sign_status[item] ? 1 : 0
				}
				setting_save_status({
					data: {
						sign_status: obj
					}
				}).then(res=> {
					this.get_info()
				})
				
			},
			change_info() {
				let arr = [];
				if (this.primary == 1) {
					arr = this.drum;
				} else {
					arr = this.bottled;
				}
				this.new_info = {}
				let new_info = {
					show_goods:false,
					empty_info:[],
					empty_status:false,
					price:[],
					sales_info:[],
				}
				arr.forEach(item=>{
					if (item.id == 'outcomeGoodsInfoList') {
						new_info.show_goods = item.value
					} else if (item.id == 'stockCount' || item.id == 'creditCount' || item.id == 'availableCreditCount' || item.id == 'borrowCount' || item.id == 'availableCount') {
						if (item.value) {
							new_info.empty_info.push(item)
							new_info.empty_status = true
						}
					} else if (item.id == 'totalOutcomeMoney' || item.id == 'sellerAvailableMoney') {
						if (item.value) {
							new_info.price.push(item)
						}
					} else {
						new_info.sales_info.push(item)
					}
				})
				this.new_info = new_info
			}
		}
	}
</script>

<style lang="less" scoped>
	.push_title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 8px;
	}

	.push_box {
		width: 560px;
		height: auto;
		padding: 16px;
		border: 1px solid #ccc;
		margin-bottom: 12px;
		padding-bottom: 9px;
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;
	}

	.push_item {
		height: auto;
		line-height: 40px;
		.lable_text {
			width: 70px;
			display: inline-block;
		}

		display: flex;

		.good_item {
			height: 70px;

			p {
				height: 20px;
			}
		}
	}

	.go_more {
		margin-top: 10px;
		border-top: 1px solid #ccc;
		display: flex;
		align-items: center;
		span:nth-child(1) {
			flex: 4;
			margin-top: 10px;
		}

		span:nth-child(2) {
			margin-top: 5px;
			flex: 1;
			text-align: right;
		}
	}

	.add_man {
		color: #00A3FF;
        margin-top: 30px;
	}

	.cont_box {
		align-items: center;
		display: flex;

		.cont_item {
			margin-top: 10px;
            width: 300px;
			p {
				height: 28px;
				line-height: 28px;
				// width: 460px;
			}
		}

		.del_iocn {
			margin-left: 35px;
			margin-top: 10px;
		}

	}

	.checked_btn {
		margin-top: 20px;
	}

	.tab_box {
		display: flex;
		width: 400px;
		margin-top: 10px;
	}

	.tab_title {
		flex: 1;
		display: flex;

		.tab_item {
			flex: 1;

			div {
				display: flex;


				p {
					height: 40px;
					width: 150px;
					text-align: center;
					line-height: 40px;
					border: 1px solid #ccc;
				}

			}

			.title_box {
				flex-direction: row;
			}

			.item_box {
				flex-direction: column;
				display: flex;

				div {
					flex-direction: row;

					p {
						height: 40px;
						width: 150px;
						text-align: center;
						line-height: 40px;
						border: 1px solid #ccc;
					}
				}
			}
		}

	}
	.right_btn{
		margin-left: 10px;
	}

	.com_box {
		display: flex;
		margin:5px 0;
		.com_info {
			margin-right: 20px;
		}

		// .info_color {
		// 	color:#ccc
		// }
	}

	.info_box {
		display: flex;
		justify-content: space-between;
		.phone {
			// float: right;
			// width: 300px;
			// border: 1px solid #00A3FF;
			margin-top: 50px;
			// padding: 10px;
			width: 300px;
			height: 541px;
			border: 10px solid #000000;
			// background: url(../.././assets/img/phoneBg.png) no-repeat;
			background-size: 100% 100%;
			border-radius: 30px;
			position: relative;

			.show_box {
				border-radius: 25px;
				height: 522px;
				background: #F7F7F7;
			}
		}
	}

	.sign {
		margin-bottom: 10px;
	}
	
</style>
