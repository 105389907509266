<template>
    <div class="dealer" style="font-size: 12px !important;">
        <div class="list_url">
            <img src="../../.././assets/img/notice.png" />
            <p> 消息列表</p>
        </div>
        <div class="header_top">
            <div class="user_info">
                <div class="user_name">
                    润田动销销售出库单
                    <!-- -{{info.type==1?'桶装':'瓶装'}} -->
                </div>
            </div>
        </div>
        <div class="info">
            <!--  <div class="info_item" v-for="(item,index) in info.data1" :key='index'>
            <span>{{item.name}}:</span><span>{{item.value}}</span>
        </div> -->
            <div v-for="(item,index) in new_info.sales_info" :key="index">
                <div class="info_item" v-if="item.value">
                    <span>{{item.name}}：</span><span>暂无数据</span>
                </div>
            </div>
        </div>
        <div class="material" v-if="new_info.show_goods">
            <div class="material_title">
                产品明细
            </div>
            <div class='good_info table_box'>
                <table class="section">
                    <tr class="head">
                        <th v-for="(item,index) in new_combination" :key="index" class="item">{{item.name}}</th>
                    </tr>
                    <tr class="row">
                        <td v-for="(item,index) in new_combination" :key="index" class="item" style="font-weight: normal !important;">暂无数据</td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="material" v-if="new_info.empty_status">
            <div class="material_title">
                空桶数量
            </div>
            <div class='good_info'>
                <div class="info_item" v-for="(item,index) in new_info.empty_info" :key="index">
                    <span>{{item.name}}：</span><span>100</span>
                </div>
            </div>

        </div>
        <div class="material other_info" v-if="new_info.price.length > 0">
            <p class='' v-for="(item,index) in new_info.price" :key="index">
                <span>{{item.name}}</span>
                <span>0元</span>
            </p>
        </div>

        <div class="material" v-if="sign_status.distribute">
            <div class="material_title">
                签名
            </div>
            <div style="width:100%">
                    <img src="https://crm-img.runtian.cn/crm-dev.waakuu.com/manage/20220923/ba0d5387f42636f7c7a13ea08b31e62c/1663914572425.jpeg?x-oss-process=style/visit" style="width:100%;height:auto">
            </div>
        </div>
        <div class="material" v-else>
            <div class="footer">确定</div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        id: {
            type: Number,
            default: 0
        },
        new_combination: {
            type: Array,
            default: []
        },
        new_info: {
            type: Object,
            default: {
                show_goods:false,
				empty_info:[],
				empty_status:false,
				price:[],
				sales_info:[],
            }
        },
        sign_status: {
            type: Object,
            default: []
        }
    },
    data() {
        return {
            sale_info:[],
            show_goods:true,
            empty_goods:[],
            price:[]
        }
    },
    created(e) {
        
    },

    methods: {

    }
}
</script>

<style lang="less" scoped>
.dealer {
    overflow: auto;
    height: 522px;
    border-radius: 20px;
}

.info {
    background: #fff;
    padding: 8px;

    .info_item {
        display: flex;
        margin-top: 3px;

        span:nth-child(1) {
            // width: px;
            flex: 1;
            margin-right: 10px;
            text-align: justify;
            color: #999999;
        }

        span:nth-child(2) {
            flex: 1;
            color: #333;
        }
    }
}

.material {
    margin-top: 10px;
    background: #fff;

    .material_title {
        height: 30px;
        line-height: 30px;
        opacity: 1;
        font-size: 30rpx;
        border-bottom: 1px solid #E4E3E5;
        padding-left: 8px;
    }
}



.header_top {
    height: 30px;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 8px;
    padding-top: 5px;
}

.good_info {
    padding:8px;
    font-size: 12px;
    color: #666666;
    p {
        line-height: 25px;
    }

    .info_item {
        margin-top: 5px;
        display: flex;
        span:nth-child(1) {
            // width: px;
            flex: 1;
            margin-right: 10px;
            text-align: justify;
            color: #999999;
        }

        span:nth-child(2) {
            flex: 1;
            color: #333;
        }
    }
}

.table_box {
    font-size: 12px;
    color: #333333;
    overflow-x: scroll;

    .item {
        font-weight: normal;
        min-width: 85px;
        min-height: 30px;
        max-width: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FAFAFA;
        box-sizing: border-box;
        border: 1px solid #E8E8E8;
        margin-left: -1px;
        margin-top: -1px;
        padding: 0 5px 0 5px;
        text-align: center;
    }

    .head {
        display: flex;
    }

    .row {
        display: flex;
        .item {
            font-weight: normal;
            font-size: 12px;
            background: #fff;
        }
    }
}

.other_info {

    padding: 4px 8px;

    p {
        height: 30px;
        line-height: 30px;
        display: flex;
        font-size: 12px;

        span:nth-child(1) {
            flex: 1;
            color: #666666;
        }

        span:nth-child(2) {
            flex: 1;
            text-align: right;
            color: #000000;
        }
    }

    p:nth-child(1) {
        border-bottom: 1px solid #e6e6e6;
    }
}

.list_url {
    height: 30px;
    background: #fff;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-left: 8px;
    img {
        width: 15px;
        height: 15px;
        margin-right: 16px;
    }
}

.footer {
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    background-color: #397DEE;
    margin-top: 18px;
    margin-bottom: 15px;
}
</style>